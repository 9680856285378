import { useState } from "react";

export const useToggle = () => {
  const [on, setOn] = useState(true);

  const toggle = () => {
    setOn((prev) => !prev);
  };

  return {
    on,
    toggle,
  };
};
