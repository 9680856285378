import { useEffect } from "react";
import cancel from "../../images/cancel.svg";


export default function Popup({
	children,
	title,
	onClose,
	textRight,
	width,
	noPadding,
	flag,
	className,
	height,
	others,
}) {
	useOverflow();
	return (
		<div className='p-fixed full-height full-width popup fade-in'>
			<div className='d-flex j-center'>
				<div
					className={`popup-content p-relative ${className || ""}`}
					style={{
						width: width ?? "35%",
						padding: !noPadding ? "30px" : "0px",
						height: height,
					}}
				>
					<div className='title-area d-flex align-center'>
						<p
							className={`flex-one ${
								textRight ? "" : "text-center"
							} text-color font-size-20 fw700 text-capitalize`}
						>
							{title}
						</p>
						{!flag && (
							<button
								type='button'
								onClick={onClose}
								className='unstyle-button c-pointer rounded-widget text-center text-white container-size-small d-flex align-center j-center'
							>
								<img src={cancel} alt='close action' />
							</button>
						)}
					</div>
					{others ? (
						<div className='d-flex flex-column j-space-between full-height'>
							{children}
						</div>
					) : (
						children
					)}
				</div>
			</div>
		</div>
	);
}

function useOverflow() {
	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = "auto");
	}, []);
}
