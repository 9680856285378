import { AboutDropin } from "./AboutPage";
import "./App.css";
import { Criteria } from "./Criteria";
import { HeadPage } from "./HeadPage";
import { Prizes } from "./Prizes";
import { AppFooter } from "@vpay/component-library";
import { DocInfo } from "./DocInfo";
import { BottomSection } from "./BottomSection";
import Modal from "react-modal";
import { FormModal } from "./Modal";
import { RegisterDataProvider, useModalContext } from "./state";

Modal.setAppElement("#root");

function App() {
	const { open, setOpen } = useModalContext();
	return (
		<div className='App'>
			<RegisterDataProvider>
				<HeadPage />
				<AboutDropin />
				<Criteria />
				<Prizes />
				<DocInfo />
				<BottomSection />
				{open && <FormModal />}
				<AppFooter footerText='VPay is a property of Minerva Technologies Ltd, a company duly registered with RC Number 1746271. VPay and Minerva Technologies Ltd are legal entities in Nigeria, that provide financial services through its different platforms.' />
			</RegisterDataProvider>
		</div>
	);
}

export default App;
