import "./index.css";

import { ReactComponent as CircleVPay } from "../circle-vpay.svg";
import { Fade } from "react-reveal";

export const AboutDropin = () => {
  return (
    <div className="about-dropin">
      <Fade bottom>
        <div className="container flex-wrap">
          <div className="left-content">
            <h3>VPay</h3>
            <p className="bold-text">
              VPay is designed to make it easy for businesses and merchants to <br />
              accept payments in a seamless and secure way{" "}
            </p>
            <p className="other-con">
              In this hackathon we’re looking for innovative solutions that integrate VPay Dropin into existing systems, or new products and services that take advantage of its capabilities
              <span>Whether you are a developer working on a new e-commerce platform, or a startup with a disruptive payment solution, we want to see what you can create </span>
            </p>
          </div>
          <CircleVPay />
        </div>
      </Fade>
    </div>
  );
};
