import { ReactComponent as Topology } from "./topology.svg";
import { ReactComponent as Crown } from "./crown.svg";
import "./index.css";

export const Prizes = () => {
  return (
    <div className="prizes-wrapper">
      <Topology />
      <div className="container content-padding place-middle">
        <h2 className="title-text">Hackathon Prizes </h2>
        <div className="prizes-box-flex">
          <PrizeBox rank="2nd" amount="100,000" />
          <PrizeBox rank="1st" className="vpay-bg-gradient" amount="150,000" />
          <PrizeBox rank="3rd" amount="50,000" />
        </div>
      </div>
    </div>
  );
};

const PrizeBox = ({ rank, amount, className = "" }) => {
  return (
    <div className={`prizes-box ${className}`}>
      {rank === "1st" && <Crown />}
      <span className="rank">{rank} Place</span>
      <h3>₦{amount}</h3>
      <span className="cash-prize">Cash Prize</span>
    </div>
  );
};
