import { ReactComponent as Code } from "./code.svg";
import { ReactComponent as Bulb } from "./bulb.svg";
import { ReactComponent as Brush } from "./brush.svg";

import "./index.css";
import { Fade } from "react-reveal";

const items = [
	{
		title: "Code Quality",
		icon: <Code />,
		content:
			"Consider ensuring that the code is readable, modular, testable, scalable, error-handling, secure and well-documented. These elements will improve the overall quality, reliability and maintainability of the code.",
	},
	{
		title: "Clean UI Designs",
		icon: <Brush />,
		content:
			"Include designs that are visually appealing, user-friendly, and intuitive. The designs should also follow industry standards for accessibility and usability, ensuring a positive user experience for all users.",
	},
	{
		title: "Overall Product Creativity ",
		icon: <Bulb />,
		content:
			"The product should be original, innovative and solve a real-world problem in a unique and interesting way. This can be achieved through a combination of original ideas, novel approaches, and the use of cutting-edge technology to create a product that stands out and makes a difference.",
	},
];

export const Criteria = () => {
	return (
		<div className='criteria-wrapper'>
			<Fade bottom>
				<div className='container content-padding flex-content'>
					{items.map((item) => (
						<CriteriaItem key={item.title} {...item} />
					))}
				</div>
			</Fade>
		</div>
	);
};

export const CriteriaItem = ({ icon, title, content, url = "" }) => {
	return (
		<div className='criteria-item'>
			{icon}
			<h3>{title}</h3>
			<p>{content}</p>
			{url && (
				<a target='_blank' rel='noreferrer' href={url}>
					View doc
				</a>
			)}
		</div>
	);
};
