import React, { useContext, useState, createContext } from "react";

export const ModalContext = React.createContext({
	open: false,
	setOpen: () => {},
});

export const useModalContext = () => useContext(ModalContext);

export const ModalContextWrapper = ({ children }) => {
	const [open, setOpen] = useState(false);

	return (
		<ModalContext.Provider value={{ open, setOpen }}>
			{children}
		</ModalContext.Provider>
	);
};

const RegisterContext = createContext({});

export const useRegisterContext = () => useContext(RegisterContext);
export const RegisterDataProvider = ({ children }) => {
	const [similarProjects, setSimilarProjects] = useState("");
	const [hasPaymentIdea, setHasPaymentIdea] = useState("");
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [location, setLocation] = useState("");
	const [summary, setSummary] = useState("");
	const [languages, setLanguages] = useState("");
	const [acceptTerms, setAcceptTerms] = useState(false);

	return (
		<RegisterContext.Provider
			value={{
				similarProjects,
				setSimilarProjects,
				hasPaymentIdea,
				setHasPaymentIdea,
				email,
				setEmail,
				name,
				setName,
				phone,
				setPhone,
				location,
				setLocation,
				summary,
				setSummary,
				languages,
				setLanguages,
				acceptTerms,
				setAcceptTerms,
			}}
		>
			{children}
		</RegisterContext.Provider>
	);
};
