import { notification } from "antd";
export const validateRegister = (values) => {
	let errors = {};
	if (!values.name) {
		errors.contactfirstname = "Name is required";
	}
	if (!values.languages) {
		errors.languagese = "Language is required";
	}
	if (!values.phone) {
		errors.phone = "Phone number is required";
	}
	if (values.phone.length !== 11) {
		errors.phone = "Phone number must be 11 characters";
	}
	if (
		(values.phone.length !== 11 || values.phone[0] !== "0") &&
		values.phone.length > 0
	) {
		errors.phone = "Phone number must start with '0' and must be 11 characters";
	}
	if (!values.location) {
		errors.password = "Location is required";
	}
	if (!values.summary) {
		errors.password = "Summary is required";
	}
	if (!values.email) {
		errors.email = "Email is required";
	} else if (!/\S+@\S+\.\S+/.test(values.email)) {
		errors.email = "Email address is invalid";
	}
	return errors;
};

export const openNotificationWithIcon = (type, title, description) => {
	notification[type]({
		message: title,
		description: description,
		style: { zIndex: "9999", borderRadius: "10px" },
	});
};
