import { registerUrl } from "./url";
import axios from "axios";

export const startSignup = (data) => {
	return axios({
		method: "POST",
		url: registerUrl,
		data,
	});
};
