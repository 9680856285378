import { ReactComponent as Spiral } from "../spiral.svg";
import { ReactComponent as AppLogo } from "./logo.svg";
import { AppButton } from "@vpay/component-library";

import "./index.css";
import { useToggle } from "../useToggle";
import { useModalContext } from "../state";

export const HeadPage = () => {
  const { setOpen } = useModalContext();
  return (
    <div className="head-page-wrapper">
      <div className="spiral">
        <Spiral />
      </div>
      <ColorSpread className="yellow" />
      <ColorSpread className="red" />
      <div className="container full-height">
        <div className="head-nav">
          <AppLogo className="head-nav-logo"/>
          <AppButton name="Register Now" isActive={true} isBusy={false} onClick={() => setOpen(true)} className="curved-btn hide-mobile" />
        </div>
        <div className="content-box">
          <ToggleInfo />
          <h1 className="head-text">
            VPay Developer <br />
            Hackathon
          </h1>
          <span className="head-meta">
            We’re excited to invite developers and startups to join us in building the future of payments in Africa. Our hackathon would run from 17th February 2023 till 10th March 2023
          </span>
          <AppButton name="Register Now" isActive={true} isBusy={false} onClick={() => setOpen(true)} className="curved-btn" />
        </div>
      </div>
    </div>
  );
};

const ColorSpread = ({ className }) => {
  return <div className={`spread ${className ?? className} `}></div>;
};

const ToggleInfo = () => {
  const { on, toggle } = useToggle();
  return (
    <div className="toggle-wrapper">
      <span onClick={toggle} className={`${on && "white-bg"}`}>
        Registration Deadline
      </span>
      <span onClick={toggle} className={`${!on && "white-bg"}`}>
        {" "}
        13th February 2023
      </span>
    </div>
  );
};
