import { AppButton, AppInput, Spacer } from "@vpay/component-library";
import { useState } from "react";
import { Checkbox, RadioButton } from "../components/RadioButton/radio_button";
import { startSignup } from "../services/apiservices";
import { useModalContext, useRegisterContext } from "../state";
import Fade from "react-reveal/Fade";
import Popup from "../components/popup/popup";
import {
	openNotificationWithIcon,
	validateRegister,
} from "../validation/register_validation";
import "./index.css";

export const FormModal = () => {
	const [, setError] = useState({});
	const [loading, setLoading] = useState(false);

	const {
		similarProjects,
		setSimilarProjects,
		hasPaymentIdea,
		setHasPaymentIdea,
		email,
		setEmail,
		name,
		setName,
		phone,
		setPhone,
		location,
		setLocation,
		summary,
		setSummary,
		languages,
		setLanguages,
		acceptTerms,
		setAcceptTerms,
	} = useRegisterContext();

	const onSimilarProjectChange = (e) => {
		setSimilarProjects(e.target.value);
	};
	const onPaymentGatewayChange = (e) => {
		setHasPaymentIdea(e.target.value);
	};
	const onEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const onNameChange = (e) => {
		setName(e.target.value);
	};
	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};
	const onLocationChange = (e) => {
		setLocation(e.target.value);
	};
	const onSummaryChange = (e) => {
		setSummary(e.target.value);
	};
	const onLanguageChange = (e) => {
		setLanguages(e.target.value);
	};

	const onChecked = (e) => {
		setAcceptTerms(e.target.checked);
	};

	const data = {
		name,
		email,
		phone,
		location,
		languages,
		summary,
	};

	const onClick = async (e) => {
		e.preventDefault();
		const error = validateRegister(data);
		setError(error);

		if (!/\S+@\S+\.\S+/.test(data.email) && data.email) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Email address is Invalid"
			);
			return;
		}

		if (
			(data.phone.length !== 11 || data.phone[0] !== "0") &&
			data.phone.length > 0
		) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Phone number must start with '0' and must be 11 characters"
			);
			return;
		}
		if (!data.email) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Email should not be blank"
			);
			return;
		}
		if (!data.phone) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Phone number should not be blank"
			);
			return;
		}
		if (!data.name) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Name should not be empty."
			);
			return;
		}
		if (!data.location) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"State should not be empty"
			);
			return;
		}
		if (!data.summary) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Brief summary should not be empty"
			);
			return;
		}
		if (!data.languages) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"List of programming languages should not be empty"
			);
			return;
		}

		if (!acceptTerms) {
			openNotificationWithIcon(
				"warning",
				"Warning",
				"Kindly accept VPay Hackathon terms."
			);
			return;
		}

		if (Object.keys(error).length === 0) {
			const isSimilarProject = similarProjects === "Yes" ? true : false;
			const isFamiliarWithPaymentGateway =
				hasPaymentIdea === "Yes" ? true : false;
			//send to endpoint
			const request = {
				name: data.name,
				email: data.email,
				phone: data.phone,
				state: data.location,
				additional_info: "NA",
				proficient_programming_languages: data.languages,
				summary_product_worked_on: data.summary,
				issimiler_project: isSimilarProject,
				isfamilier_with_payment_gateway: isFamiliarWithPaymentGateway,
			};
			try {
				setLoading(true);
				const res = await startSignup(request);
				if (res?.status) {
					openNotificationWithIcon(
						"success",
						"Success",
						"You have successfully registered for the VPay Hackathon"
					);
				}
				setName("");
				setEmail("");
				setPhone("");
				setLocation("");
				setLanguages("");
				setSummary("");
				setAcceptTerms(false);
				setHasPaymentIdea("No");
				setSimilarProjects("No");
				setOpen(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error.response?.data?.message);
				return openNotificationWithIcon(
					"error",
					"Error",
					error.response?.data?.message ?? "error"
				);
			}
		}
	};

	const { open, setOpen } = useModalContext();

	return (
		<Popup width='45%' onClose={() => setOpen(false)} className='fade_in'>
			<div className='d-flex j-center align-center flex-column'>
				<form className='form-wrapper width75'>
					<Spacer height={20} />
					<p className='register-form-header'>
						Register for the VPay Hackathon
					</p>
					<Spacer height={30} />
					<div>
						<p className='label'>Email</p>
						<AppInput
							placeholder='Enter your email here...'
							name='email'
							onChange={(e) => onEmailChange(e)}
						/>
					</div>
					<div>
						<p className='label'>Name</p>
						<AppInput
							placeholder='Enter your name here...'
							name='name'
							onChange={(e) => onNameChange(e)}
						/>
					</div>
					<div>
						<p className='label'>Phone Number</p>
						<AppInput
							placeholder='Enter your phone number here...'
							name='phone'
							onChange={(e) => onPhoneChange(e)}
						/>
					</div>
					<div>
						<p className='label'>State</p>
						<AppInput
							placeholder='Enter your state of residence here...'
							name='location'
							onChange={(e) => onLocationChange(e)}
						/>
					</div>
					<div>
						<p className='label'>
							Brief summary of the product you want to develop
						</p>
						<Spacer height={8} />
						<textarea
							placeholder='Enter a brief summary of the product...'
							name='summary'
							onChange={(e) => onSummaryChange(e)}
							className='full-width'
						></textarea>
					</div>
					<Spacer height={25} />
					<div>
						<p className='label'>
							What programming language(s) are you proficient in?
						</p>
						<Spacer height={8} />
						<textarea
							placeholder='Enter a list of programming languages seperated by comma...'
							name='languages'
							onChange={(e) => onLanguageChange(e)}
							className='full-width'
						></textarea>
					</div>
					<Spacer height={35} />
					<div>
						<p className='label'>
							Have you worked on any similar projects before?
						</p>
						<Spacer height={5} />
						<RadioButton
							label='Yes'
							id='similarProject'
							value='Yes'
							name='similarProject'
							isSelected={similarProjects === "Yes"}
							onChange={onSimilarProjectChange}
						/>
						<Spacer height={5} />
						<RadioButton
							label='No'
							id='similarProject'
							value='No'
							name='similarProject'
							isSelected={similarProjects === "No"}
							onChange={onSimilarProjectChange}
						/>
					</div>
					<Spacer height={20} />
					<div>
						<p className='label'>
							Are you familiar with payment gateway integration?
						</p>
						<Spacer height={5} />
						<RadioButton
							label='Yes'
							id='paymentGateway'
							value='Yes'
							name='paymentGateway'
							isSelected={hasPaymentIdea === "Yes"}
							onChange={onPaymentGatewayChange}
						/>
						<Spacer height={5} />
						<RadioButton
							label='No'
							id='paymentGateway'
							value='No'
							name='paymentGateway'
							isSelected={hasPaymentIdea === "No"}
							onChange={onPaymentGatewayChange}
						/>
					</div>
					<Spacer height={20} />
					<div>
						<Checkbox
							label='By submtting this form, you agree to the terms and conditions of
							the VPay Hackathon'
							onChecked={onChecked}
						/>
					</div>
					<Spacer height={50} />
					<AppButton
						name='Submit'
						isActive={true}
						isBusy={loading}
						onClick={(e) => onClick(e)}
						className='submit-btn'
					/>
					<Spacer height={20} />
				</form>
			</div>
		</Popup>
	);
};
