//const baseUrl = process.env.REACT_APP_ENV === 'dev' ? `https://pluto.vpay.africa/` : `https://saturn.vpay.africa/`;
const baseUrl = `https://pluto.vpay.africa/`;
export const registerUrl = `${baseUrl}service/demo/v1/query/hackathon/user/add`;


// <Modal
// 			isOpen={open}
// 			onRequestClose={() => setOpen(false)}
// 			// style={customStyles}
// 			className="modal"
// 		>
// 			<div className='d-flex j-center align-center flex-column'>
// 				<form className='form-wrapper width75'>
// 					<Spacer height={20} />
// 					<p className='register-form-header'>
// 						Register for the VPay Hackathon
// 					</p>
// 					<Spacer height={30} />
// 					<div>
// 						<p className='label'>Email</p>
// 						<AppInput
// 							placeholder='Enter your email here...'
// 							name='email'
// 							onChange={(e) => onEmailChange(e)}
// 						/>
// 					</div>
// 					<div>
// 						<p className='label'>Name</p>
// 						<AppInput
// 							placeholder='Enter your name here...'
// 							name='name'
// 							onChange={(e) => onNameChange(e)}
// 						/>
// 					</div>
// 					<div>
// 						<p className='label'>Phone Number</p>
// 						<AppInput
// 							placeholder='Enter your phone number here...'
// 							name='phone'
// 							onChange={(e) => onPhoneChange(e)}
// 						/>
// 					</div>
// 					<div>
// 						<p className='label'>State</p>
// 						<AppInput
// 							placeholder='Enter your state of residence here...'
// 							name='location'
// 							onChange={(e) => onLocationChange(e)}
// 						/>
// 					</div>
// 					<div>
// 						<p className='label'>
// 							Brief summary of the product you want to develop
// 						</p>
// 						<Spacer height={5} />
// 						<textarea
// 							placeholder='Enter a brief summary of the product...'
// 							name='summary'
// 							onChange={(e) => onSummaryChange(e)}
// 							className="full-width"
// 						></textarea>
// 					</div>
// 					<Spacer height={15} />
// 					<div>
// 						<p className='label'>
// 							What programming language(s) are you proficient in?
// 						</p>
// 						<AppInput
// 							placeholder='Enter a list of programming languages seperated by comma...'
// 							name='languages'
// 							onChange={(e) => onLanguageChange(e)}
// 						/>
// 					</div>
// 					<div>
// 						<p className='label'>
// 							Have you worked on any similar projects before?
// 						</p>
// 						<Spacer height={5} />
// 						<RadioButton
// 							label='Yes'
// 							id='similarProject'
// 							value='Yes'
// 							name='similarProject'
// 							isSelected={similarProjects === "Yes"}
// 							onChange={onSimilarProjectChange}
// 						/>
// 						<Spacer height={5} />
// 						<RadioButton
// 							label='No'
// 							id='similarProject'
// 							value='No'
// 							name='similarProject'
// 							isSelected={similarProjects === "No"}
// 							onChange={onSimilarProjectChange}
// 						/>
// 					</div>
// 					<Spacer height={20} />
// 					<div>
// 						<p className='label'>
// 							Are you familiar with payment gateway integration?
// 						</p>
// 						<Spacer height={5} />
// 						<RadioButton
// 							label='Yes'
// 							id='paymentGateway'
// 							value='Yes'
// 							name='paymentGateway'
// 							isSelected={hasPaymentIdea === "Yes"}
// 							onChange={onPaymentGatewayChange}
// 						/>
// 						<Spacer height={5} />
// 						<RadioButton
// 							label='No'
// 							id='paymentGateway'
// 							value='No'
// 							name='paymentGateway'
// 							isSelected={hasPaymentIdea === "No"}
// 							onChange={onPaymentGatewayChange}
// 						/>
// 					</div>
// 					<Spacer height={20} />
// 					<div>
// 						<Checkbox
// 							label='By submtting this form, you agree to the terms and conditions of
// 							the VPay Hackathon'
// 							onChecked={onChecked}
// 						/>
// 					</div>
// 					<Spacer height={50} />
// 					<AppButton
// 						name='Submit'
// 						isActive={true}
// 						isBusy={loading}
// 						onClick={(e) => onClick(e)}
// 						className='submit-btn'
// 					/>
// 					<Spacer height={20} />
// 				</form>
// 			</div>
// 		</Modal>