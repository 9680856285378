import "./radio_button.css";

export const RadioButton = ({
	onChange,
	id,
	isSelected,
	label,
	value,
	name,
}) => {
	return (
		<label className='radio'>
			<input
				name={name}
				type='radio'
				onChange={(e) => onChange(e)}
				value={value}
			/>
			<span>{label}</span>
		</label>
	);
};
export const Checkbox = ({ label, onChecked }) => {
	return (
		<div className='full-width'>
			<label>
				<input
					type='checkbox'
					style={{ marginRight: "8px", cursor: "pointer" }}
					onChange={(e) => onChecked(e)}
				/>
				<span className='label-checkbox'>{label}</span>
			</label>
		</div>
	);
};
