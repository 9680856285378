import { AppButton } from "@vpay/component-library";
import { useModalContext } from "../state";
import "./index.css";

export const BottomSection = () => {
  const { setOpen } = useModalContext();
  return (
    <div className="bottom-section">
      <div className="container bt-flex content-padding">
        <h2>We can’t wait to see what you’ll build </h2>
        <p>We’re excited to invite developers and startups to join us in building the future of payments in Africa </p>
        <AppButton name="Register" isActive={true} isBusy={false} onClick={() => setOpen(true)} className="curved-btn" />
      </div>
    </div>
  );
};
