import "./index.css";

import { ReactComponent as WebCheckoutIcon } from "./webicon.svg";
import { ReactComponent as MobileCheckoutIcon } from "./mobileicon.svg";
import { ReactComponent as APICheckoutIcon } from "./apiicon.svg";
import { CriteriaItem } from "../Criteria";
import { Fade } from "react-reveal";

const items = [
  {
    title: "Web Checkout Gateway",
    icon: <WebCheckoutIcon />,
    content: "You can easily integrate our web checkout gateway to collect payments from your customers via cards and account transfers on your web platform.",
    url: "https://docs.vpay.africa/vpay-js-inline-dropin-integration-guide/3.-web-app-setup",
  },
  {
    title: "Mobile Checkout Gateway",
    icon: <MobileCheckoutIcon />,
    content: "You can accept card and account transfer payments from your customer’s in-app using the Vpay mobile checkout gateway.",
    url: "https://docs.vpay.africa/vpay-js-inline-dropin-integration-guide/4.-mobile-app-setup",
  },
  {
    title: "API",
    icon: <APICheckoutIcon />,
    content: "You have everything you need to build your own custom projects and products using our well-documented APIs.",
    url: "https://docs.vpay.africa/vpay-api-specification-basic-gude/",
  },
];

export const DocInfo = () => {
  return (
    <div className="doc-info-wrapper">
      <Fade bottom>
        <div className="container content-padding flex-content">
          {items.map((item) => (
            <CriteriaItem key={item.title} {...item} />
          ))}
        </div>
      </Fade>
    </div>
  );
};
